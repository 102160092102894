<template>
  <div>

    <Contact/>
    <section class="slice slice-lg">
      <div class="container">
        <div class="row row-grid justify-content-between align-items-center">
          <div class="col-lg-5">
            <h3>工业软件实施服务</h3>
            <p class="lead my-4">
              <a href="javascript:;">实施方法学指导下的专业实施能力</a>
            </p>
            <p>
              结合国外先进PLM厂商的实施方法学，与团队丰富的本土实施经验，打造项目成功的基石。
            </p>
            <p>
              针对公司自研产品，提供信息化咨询、软件安装与部署、配置与定制开发、数据迁移、培训、技术支持、升级与优化等全面技术服务。
            </p>
            <p>
              丰富的国外工业软件实施经验，可提供包括PTC公司的Windchill、 Integrity，西门子公司的Teamcenter等产品全面的实施服务。
            </p>
            <p>
              团队成员成功实施项目经验超过五十个，覆盖各类离散型制造企业，具有良好口碑。
            </p>
          </div>
          <div class="col-lg-6">
            <el-carousel ref="carousel" :interval="4000" :loop="true" trigger="click">
              <el-carousel-item class="slide-1">
              </el-carousel-item>
              <el-carousel-item class="slide-2">
              </el-carousel-item>
              <el-carousel-item class="slide-3">
              </el-carousel-item>
            </el-carousel>

          </div>
        </div>
      </div>
    </section>
    <section class="slice slice-lg">
      <div class="container">
        <div class="row row-grid justify-content-between align-items-center">
          <div class="col-lg-5">
            <h3>咨询规划类能力</h3>
            <p class="lead my-4">
              <a href="javascript:;">数字化工厂咨询规划</a>
            </p>
            <p>
              以生产计划为主线、以制造网络监控为支撑、以生产制造管理为核心，建设面向产品全生命周期管理的数字化制造平台，为企业智能生产与智能制造打下坚实的基础。通过合理的流程优化，CAD、CAM、CAPP、MES、ERP、DNC等应用系统与PDM系统的有效集成，以及利用MES综合管控物料、设备、人员、流程指令等所有工厂车间的相关资源，实现面向车间的设计制造一体化管理。同时依托计算机网络，建设制造网络监控平台，实现数控机床的数控文件、参数、状态、数据与安全和监测与控制综合管理。
            </p>
          </div>
          <div class="col-lg-6">
            <el-carousel ref="carousel" :interval="4000" :loop="true" trigger="click">
              <el-carousel-item class="slide-4">
              </el-carousel-item>
              <el-carousel-item class="slide-5">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </section>
    <section class="slice slice-lg">
      <div class="container">
        <div class="row row-grid justify-content-between align-items-center">

          <div class="col-lg-6">
            <el-carousel ref="carousel" :interval="4000" :loop="true" trigger="click">
              <el-carousel-item class="slide-6">
              </el-carousel-item>
              <el-carousel-item class="slide-7">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="col-lg-5">
            <!-- <h3>咨询规划类能力</h3> -->
            <p class="lead my-4">
              <a href="javascript:;">智慧院所咨询规划</a>
            </p>
            <p>
              在“十三五”精神的倡导下，我们积极投身于各大军工企业的智慧院所规划中，专业的团队、认真负责的精神获得了客户的一致认可。
            </p>
            <p>
              公司的智慧院所规划从物联网、互联网、无线通信技术等多个方面扩大感知世界的“触角”，拓展数据来源和渠道，将信息世界与物理世界紧密融合，最终实现信息化与工业化的深度融合。
            </p>
            <p>
              公司的智慧院所规划从物联网、互联网、无线通信技术等多个方面扩大感知世界的“触角”，拓展数据来源和渠道，将信息世界与物理世界紧密融合。
            </p>
            <p>
              最终实现信息化与工业化的深度融合。最终，实现基于大数据的挖掘月分析，使得信息价值真正得以体现并形成“智慧”。 </p>
          </div>
        </div>
      </div>
    </section>
    <section class="slice slice-lg">
      <div class="container">
        <div class="row row-grid justify-content-between align-items-center" style="flex-flow:row-reverse">
          <div class="col-lg-12">
            <h3 class="text-center">基于信息系统的业务流程优化</h3>
            <p class="lead my-4 text-center">
              <a href="javascript:;">制造业核心业务与信息化的贯穿关系</a>
            </p>
            <p>
              为了充分发挥工业软件给企业带来价值，需要基于系统合理搭配新的业务流程来实现。这里既需要对软件系统有深入了解，又需要对企业业务流程有深刻认识。最终新流程能实现下列价值：
              可以减少繁琐的手工操作和重复性工作，可以提高工作效率和生产力。
            </p>
            <p>
              系统的自动化和规范化特性可以消除人为错误，确保数据的准确性，提高业务决策的可靠性。
              促进跨部门协作与沟通，实现数据共享，优化资源配置，提高团队协作效率。
            </p>
            <div class="flex_jc">
              <img src="@/img/case/5.png" style="width: auto; margin: 0 auto;"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>
<script>

export default {

  data() {
    return {
      swiperOption: {
        autoplay: true,
        loop: true,
        effect: 'flip',
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.flex_jc{
  display: flex;
  justify-content: center;
  text-align: center;
}
.slide-1 {
  background: url('../img/case/6.png') no-repeat;
  background-size: 100% 80%;
}

.slide-2 {
  background: url('../img/case/7.png') no-repeat;
  background-size: 100% 100%;
}

.slide-3 {
  background: url('../img/case/8.png') no-repeat;
  background-size: 100% 100%;
}

.slide-4 {
  background: url('../img/case/1.png') no-repeat;
  background-size: 100% 100%;
}

.slide-5 {
  background: url('../img/case/2.png') no-repeat;
  background-size: 100% 100%;
}

.slide-6 {
  background: url('../img/case/3.png') no-repeat;
  background-size: 100% 100%;
}

.slide-7 {
  background: url('../img/case/4.png') no-repeat;
  background-size: 100% 100%;
}

</style>
